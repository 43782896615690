*,::after, ::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body{
    margin: 0 100px;
    background-color: white;
  }
.cart-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }

  .cart-content {
    font-size: 15Px;
    padding-top: 30px;
  }
  
  .cart-items {
    margin-top:20px;
    margin-bottom: 20px;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
  }
  
  .item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .item-details {
    flex-grow: 1;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
  }
  
  .quantity-controls button {
    width: 30px;
    height: 30px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
  }
  
  .quantity-controls span {
    margin: 0 10px;
  }
  
  .remove-btn {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .cart-summary {
    text-align: right;
  }
  
  .checkout-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }