*,::after, ::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  max-width: 100vw;
  margin: 0;
  padding: 0 20px;
  overflow-x: hidden;
  box-sizing: border-box;
}

body{
  margin: 0 100px;
  background-color: white;
}

.MainPage {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0 15px;
  box-sizing: border-box;
}

.responsive-container {
  max-width: 100%;
  width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.MainPage-container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}


.nav{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0;
  padding-bottom: 20px;
}

.nav-top {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  justify-content: space-between;
}

.hamburger-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #000000;
  margin-right: 30px;
  
}

.hamburger-menu img {
  width: 20px;
  height: 20px;
}

.nav .logo {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.nav .logo h1{
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #000000;
  font-size: 20px;
  line-height: 20px;
}

.nav .logo b{
  color: red;
}

.order-type-container {
  margin-right: 20px;
}

.order-type-button {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
}

.order-type-button span {
  padding: 3px 15px;
  border-radius: 17px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.order-type-button .delivery {
  background-color: #ffffff;
  color: #000000;
  margin-right: 5px;
}

.order-type-button .pickup {
  color: #000000;
}

.location-icon {
  display: flex;
  align-items: center;
  margin-right: 140px;
}

.location-icon img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.search-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 40px;
  flex-grow: 1;
  flex: 1 1 300px;
  min-width: 200px;
}

.search-button {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 5px 15px;
  cursor: pointer;
  height: 36px;
  width: 100%;
  max-width: 450px;
  font-weight: lighter;
}

.search-button img {
  width: 15px;
  height: 15px;
  margin-right: 20px;
}

.search-button span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 13px;
  font-weight: 450;
  color: black;
}

.cart-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  position: relative;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.cart-icon img {
  width: 18px;
  height: 18px;
}

.nav ul{
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: flex-start;
}

.nav ul li{
  margin-right: 30px;
}

.nav ul li:last-child {
  margin-right: 0;
}

.nav ul li a{
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 17px;
  margin-left: 50px;
}

.nav ul .active::after{
  content: '';
  width: 25%;
  height: 3px;
  background-color: red;
  display: flex;
  position: relative;
  margin-left: 50px;
}

.filter-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  padding: 0 50px;
}

.filter-btn {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px 16px;
  margin: 0 5px;
}

.filter-btn img {
  width: 18px;
  height: 14px;
}

.filter-btn span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.offers-btn {
  width: 100px;
  order: -4;
  margin-right: 9px;
}

.offers-btn img {
  width: 18px;
  height: 16px;
  margin-right: 10px;
}

.delivery-fee-btn {
  width: 150px;
  margin-left: 5px;
}

.delivery-fee-btn img {
  margin-left: 3px;
}

.rating-btn {
  width: 110px;
}

.rating-btn img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.price-btn {
  width: 100px;
}

.price-btn img, .sort-btn img {
  margin-left: 10px;
}

.sort-btn {
  width: 90px;
}

.filter-btn:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

.banners-container {
  position: relative;
  margin: 20px auto;
  overflow: hidden;
  width: 90%; /* Reduce the overall width */
  max-width: 1200px;
  margin-bottom: 2px;
}

.banners {
  display: flex;
  gap: 20px;
  
  transition: transform 0.5s ease;
 
   /* Set the width to max-content to fit the banners */
  
}

.banner {
  flex: 0 0 calc(50% - 10px);
  min-width: calc(50% - 10px);
  height: 150px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;

}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
  width: calc(100% - 40px);
  padding: 15px;
}

.banner-content h3 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
}

.banner-content p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
}

.banner-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.banner-buttons {
  margin-top: 10px;
}

.banner-nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.smaller-font-banner .banner-content h3,
.smaller-font-banner .banner-content p {
  font-size: 12px; /* Adjust this value as needed */
}

.prev-btn {
  left: -40px;
}

.next-btn {
  right: -40px;
}

.category {
  width: 100%;
  display: flex;
  margin-bottom: 50px; 
  justify-content: center;
  margin-top: 50px;
}

.category .list-items{
  width: 90%;
  position: relative;
  margin-top: 100px;
}

.category .list-items h3{
  font-size: 20px;
  font-weight: 600;
  font-family: sans-serif;
  margin-bottom: 70px;
}

.category .list-items .card-list{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.category .card-list .card{
  width: 200px;
  height: auto;
  display: block;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  background: #ffffff;
  outline: 2px solid red;
}

.category .card-list .card img{
  width: 100px;
  height: auto;
  bottom: 140px;
  object-fit: cover;
  object-position: center;
  background-repeat: no-repeat;
  position: absolute;
  box-shadow: 0 20px 20px red;
  border-radius: 50%;
}

.category .card-list .card .food-title{
  padding: 40px 0 10px;
}

.side-nav {
  position: fixed;
  top: 0;
  left: -270px;
  width: 270px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
}

.side-nav.open {
  left: 0;
}

.side-nav-content {
  padding: 20px;
}

.side-nav ul {
  list-style-type: none;
  padding: 0;
  margin-top: 80px;
}

.side-nav ul li {
  margin-bottom: 40px;
}

.side-nav ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.side-nav ul li a img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.side-nav::-webkit-scrollbar {
  width: 15px;
}

.side-nav::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.side-nav::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 5px;
}

.side-nav::-webkit-scrollbar-thumb:hover {
  background: lightgrey;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 999;
}

.overlay.open {
  display: block;
}

.signin, .signup {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}
.signin {
  background: transparent;
  color: #000000;
}

.signup {
  background-color: red;
  color: white;
}

.user-info {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.user-info h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.user-info p {
  margin-bottom: 5px;
}

.user-info input {
  width: 120px;
  padding: 5px;
  margin-right: 5px;
}

.user-info button {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.user-info button:hover {
  background-color: #a4a0a0;
}
.profile-card {
  width: 300px; /* adjust the width to fit your design */
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #ff6b6b;
  font-weight: bold;
  border: 4px solid white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.user-details {
  width: 50%;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.user-details h2 {
  margin-bottom: 10px;
}

.user-details .form-group {
  margin-bottom: 5px;
}

.user-details label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.user-details input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.user-details button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.user-details button:hover {
  background-color: #ff8787;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0,0, 0.1);
}

.user-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.user-info p {
  font-size: 16px;
  margin-bottom: 8px;
  color: #666;
}

.edit-button {
  background-color: #ff6b6b;
  color: rgb(181, 5, 5);
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.edit-button:hover {
  background-color: #ff8787;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.category {
  margin-top: 10px;
}

.list-items {
  display: flex;
  justify-content: center;
}
.profile-container {
  display: flex;
  justify-content: center;
  margin-top: 30px; /* Increased top margin to move the profile card down */
  padding: 0 50px;
  padding-bottom: 20px;
}

.restaurant-tiles {
  margin: 30px 50px;
}

.restaurant-tile {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 210px;
}

.restaurant-tile:hover {
  transform: translateY(-5px);
}

.restaurant-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.restaurant-info {
  padding: 15px;
}

.restaurant-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.restaurant-cuisine {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.restaurant-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.restaurant-rating {
  display: flex;
  align-items: center;
}

.star-icon {
  color:#000;
  margin-right: 5px;
}

.restaurant-delivery-time,
.restaurant-delivery-fee {
  color: #666;
}


.food-trucks-section,
.restaurants-section,
.cafes-section {
  margin: 30px 50px;
  padding-top: 1px;
  margin-top: -50px;
}



.food-trucks-section h2,
.restaurants-section h2,
.cafes-section h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.tile-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
@keyframes bannerScroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

@media (max-width: 768px) {
  body {
    margin: 0;
    padding: 0 10px;
    overflow-x: hidden;
    width: 100%;
  }

  .MainPage {
    width: 100%;
    padding: 0 10px;
    margin: 0;
  }

  .nav-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 5px;
    margin-bottom: 20px;
    
  }

  .nav > * {
    margin-bottom: 0;
  }
  
  .nav {
    width: 100%;
    display: flex;
    
    padding: 5px 0;
  }

  

  .order-type-container,
  .location-icon,
  .search-container,
  .cart-icon {
    margin-top: 10px;
  }
  .hamburger-menu {
    margin: 0;
    display: flex;
    align-items: center;
  }
  .location-icon {
    margin: 0 0 0 20px;
    display: flex;
    align-items: center;
  }
  .search-container {
    width: 50%; /* Reduce width from 100% */
    max-width: 300px;
    flex: 1;
    margin: 0;
  }

  .search-button {
    width: 50%;
    height: 30px; /* Increase height */
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  .search-button span {
    font-size: 10px; /* Slightly larger text */
  }

  .cart-icon {
    flex-shrink: 0;
    margin: 0 0 0 10px;
  }


  .order-type-button,
  .search-button {
    width: 80%;
    justify-content: center;
  }
  
  .order-type-container {
    display: none !important;
  }
  .nav ul {
    display: flex !important; /* Force display */
    visibility: visible !important; /* Ensure visibility */
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    padding: 5px 10px 0;
    margin: 0 ; 
    width: 100%;
   /* border-bottom: 1px solid #eee;*/
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 20px; 
    padding-top: 10px 0;
    border-bottom: none;
    
  }

  .nav ul::-webkit-scrollbar {
    display: none; /* Hide scrollbar for cleaner look */
  }
  
  
  .search-cart-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 0 5px;
  }


  .nav ul li {
    display: inline-block;
    margin: 0 ;
    padding: 2px 0;
    position: relative;
  }

  .nav ul li a {
    font-size: 15px;
    font-weight: 500;
    color: #666;
    padding: 5px; /* Add horizontal padding */
    margin: 0;
    transition: color 0.3s ease;
    flex: 0 1 auto;
    white-space: nowrap;
  }
  
  .nav ul li:first-child {
    margin-left: 0;
  }

  .nav ul li:last-child {
    margin-right: 0;
  }

  .nav ul .active::after {
    margin-left: 0; /* Remove margin for active indicator */
    width: 100%; /* Make indicator full width of text */
    bottom: -2px;
    height: 2px;
  }

  .category .list-items .card-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .side-nav {
    width: 30%;
    left: -80%;
  }
  .side-nav.open {
    left: 0;
    z-index: 1000;
  }

  .overlay.open {
    display: block;
    z-index: 999;
  }
  .nav .logo {
    margin: 0;
    text-align: center;
  } 

  

  .logo-container {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }



  .filter-buttons {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 10px 0px;
    margin: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    gap: 10px;
    justify-content: flex-start;
    margin-top: 5px; /* Reduce top margin */
    padding-top: 5px; 
    white-space: nowrap;
  }
  .filter-buttons::-webkit-scrollbar {
    display: none;
  }
  .filter-btn {
    flex: 0 0 auto;
    margin: 0;
    white-space: nowrap;
    padding: 6px 12px; /* Reduced padding */
    height: 22px; /* Explicit height */
    display: flex;
    align-items: center;
    font-size: 12px;
    border-radius: 16px; 
  }

  .offers-btn {
    width: auto;
    min-width: 90px;
  }

  .rating-btn {
    width: auto;
    min-width: 95px;
  }

  .delivery-fee-btn {
    width: auto;
    min-width: 100px;
  }

  .price-btn {
    width: auto;
    min-width: 70px;
  }

  .sort-btn {
    width: auto;
    min-width: 65px;
  }

  .banners-container {
    position: relative;
    margin: 15px auto 0;
    overflow: hidden;
    width: calc(100% - 40px);; /* Adjust width to account for navigation buttons */
    max-width: none;
    /*margin-bottom: 2px;*/
    padding-bottom: 0px;
    margin-top: 10px;

  }

  .banners-container::-webkit-scrollbar {
    display: none; /* WebKit */
  }

  .banner {
   flex: 0 0 calc(50% - 5px); /* Adjust width considering smaller gap */
   min-width: calc(50% - 5px);
   height: 150px;
   background-color: #fff;
   border-radius: 15px;
   overflow: hidden;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   position: relative;
   margin-right: 0px;
    
  }

  
.banner:last-child {
  margin-right: 0;
}

  .banner-nav-btn {
    /*width: 30px;
    height: 30px;
    font-size: 18px;*/
    position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


  }

  .banners {
    display: flex;
    gap: 7px; /* Reduce gap between banners */
    transition: transform 0.5s ease;
  }

  .banner:last-child {
    margin-right: 0;
  }

  .prev-btn {
    left: -20px;
  }

  .next-btn {
    right: -20px;
  }

  .food-trucks-section,
  .restaurants-section,
  .cafes-section {
    margin: 20px;
  }

  .tile-container {
    grid-template-columns: 1fr;
  }

  .food-trucks-section {
    margin-top: 10px;
    margin-bottom: 0;
    padding-top: 0;
  }

  .food-trucks-section h2 {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .logo {
    width: 100%;
    text-align: center;
    order: -1;
    margin: 10px 0;
    flex: 0 0 auto;
  }


}

.banner-btn {
  display: inline-block;
  font-size: 12px; /* Reduce button font size */
  padding: 5px 10px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s ease;
}

.maroon-btn {
  background-color: #800000;
  color: white;
  font-size: smaller;
}

.blue-btn {
  background-color:  lightgray;
  color: white;
  font-size: smaller;
}

.green-btn {
  background-color: rgb(255, 215, 0);
  color: white;
  font-size: smaller;
}

.orange-btn {
  background-color: rgb(237, 92, 116);
  color: white;
  font-size: smaller;
}

.banner-btn:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

.orange-btn {
  background-color: rgb(237, 92, 116);
  color: white;
  font-size: smaller;
}

.banner-btn:hover {
  opacity: 0.8;
  transform: scale(1.05);
}
.food-trucks-section,
.restaurants-section,
.cafes-section {
  margin: 20px;
}
.tile-container {
  grid-template-columns: 1fr;
}
.food-truck-page {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.food-truck-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.store-info {
  flex: 1;
}

.order-options {
  display: flex;
  align-items: center;
}

.order-type-container{
  margin-right: 10px;
  width: 100%;
  margin: 10px 0;
}

.order-type-toggle {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 3px;
  margin-right: 10px;
}
.toggle-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 17px;
  cursor: pointer;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  background-color: transparent;
  color: #000000;
  margin-right: 10px;
}

.toggle-btn.delivery {
  background-color: #ff0000;
  color: black;
  height: 4px;
  width: 11px;
  margin-top: 5%;
}

.toggle-btn.pickup {
  background-color: #ff0000;
  height: 2px;
  width: 9px;
}

.toggle-btn.active {
  background-color: red; 
  color: white; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.toggle-btn:hover:not(.active){
  background-color: rgb(255,255,255,0.5);
}

.group-order-btn {
  padding: 8px 16px;
  border: none;
  background-color: #f0f0f0;
  color: black;
  border-radius: 20px;
  cursor: pointer;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  font-weight: 540;
  transition: background-color 0.3s ease;
  height: 35px;
  margin-left: 20px;
}


.group-order-btn:hover {
  background-color: #e0e0e0;
}

.food-truck-page h1 {
  font-size: 21px;
  margin-bottom: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-left: 180%;
  margin-right: 200%;
}


.food-truck-page h3{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.food-truck-page img {
  width: 110%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.food-truck-page h2 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.food-truck-page ul {
  list-style-type: none;
  padding-left: 0;
}

.food-truck-page li {
  margin-bottom: 5px;
}

.food-truck-page p {
  margin-bottom: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.food-truck-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.food-truck-header h1 {
  margin: 0;
}

.food-truck-header .search-container {
  flex-shrink: 0;
}

.food-truck-header .search-button {
  padding: 8px 16px;
  height: 35px;
  width: 290px;
  margin-left: auto;
}

.food-truck-header .search-button img {
  width: 16px;
  height: 16px;
  margin-bottom: 11px;
}

.menu-container {
  display: flex;
  margin-top: 20px;
}

.menu-nav {
  width: 200px;
  padding-right: 20px;
  border-right: 1px solid #e0e0e0;
}

.menu-nav h2 {
  margin-bottom: 10px;
}

.menu-nav ul {
  list-style-type: none;
  padding: 0;
}

.menu-nav li {
  margin-bottom: 10px;
}

.menu-nav button {
  width: 100%;
  text-align: left;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
}

.menu-nav button.active {
  font-weight: bold;
  color: #ff0000;
}

.menu-items {
  flex-grow: 1;
  padding-left: 20px;
}

.menu-items h2 {
  margin-bottom: 15px;
}

.menu-items ul {
  list-style-type: none;
  padding: 0;
}

.menu-items li {
  margin-bottom: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}


.menu-item {
  flex: 0 0 auto;
  width: 230px;
  height: 230px; 
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}


.menu-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.item-quantity {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  background-color: #ff0000;
  border-radius: 15px;
  padding: 2px;
}

.item-quantity button {
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.item-quantity span {
  color: white;
  margin: 0 5px;
}

.added-to-cart-msg {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 128, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.item-info {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.item-name {
  font-weight: bold;
  font-size: 14px;
}

.item-price {
  color: #000000;
  font-size: 14px;
}

.menu-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.menu-navigation button {
  padding: 10px 20px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.menu-navigation button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.menu-row {
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  margin-bottom: 20px;
}
.menu-row::-webkit-scrollbar {
  height: 8px;
}
.menu-row::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.menu-row::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.menu-item {
  flex: 0 0 calc(33.333% - 40px);
  max-width: calc(33.333% - 40px);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.menu-item img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.item-info {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-name {
  font-weight: bold;
  font-size: 16px;
}

.item-price {
  color: #000000;
  font-size: 14px;
}

.menu-item .image-container {
  position: relative;
  width: 100%;
  padding-top: 75%; 
}

.menu-item .image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-item .add-to-cart-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff0000;
  color: white;
  border: none;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  
}

.menu-item .add-to-cart-btn:hover {
  background-color: #cc0000;
}



.banners-container {
  padding-bottom: 5px; /* Adjust the value as needed */
}

.food-truck-section {
  padding-top: 0px; /* Adjust the value as needed */
}
.queue-time {
  font-size: 0.9em;
  color: #666;
  margin-top: 5px;
}

@media (max-width: 480px) {
  .nav ul {
    gap: 12px;
    padding: 8px 0;
  }

  .nav ul li a {
    font-size: 14px;
  }
}