.checkout-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.back-to-cart {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 15px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
}

.checkout-content {
  display: flex;
  gap: 20px;
}

.checkout-sections {
  flex: 2;
  display: flex;
}

.section-buttons {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.section-buttons button {
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  text-align: left;
  border-radius: 4px;
}

.section-buttons button.active {
  background-color: #007bff;
  color: white;
}

.section-content {
  flex-grow: 1;
  padding: 0 20px;
}

.checkout-section {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.payment-option {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  text-align: left;
  border-radius: 4px;
}

.order-summary {
  flex: 1;
  background-color: white;
  padding: 2ch;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  margin-top: 20%;
  
}

.order-summary ul {
  list-style-type: none;
  padding: 0;
}

.order-summary li {
  margin-bottom: 10px;
}

.place-order-btn {
  display: block;
  width: 25%;
  height: 44px;
  padding: 15px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 65%;
  
}

.place-order-btn:hover {
  background-color: red;
}